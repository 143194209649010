import { htmlDecode } from './htmlDecode';

// // issue persist with html entity display in <meta /> tags
// // https://github.com/facebook/react/issues/13838
// // https://github.com/zeit/next.js/issues/2006
// export function renderMetaTag(tag, translateContentFn = (t) => t) {
export function renderMetaTag(tag, translateContentFn = (t) => t) {
  delete tag.noEscape;
  tag.content = tag.content ? htmlDecode(translateContentFn(tag.content.replace(/\n/g, ' '))) : '';
  return <meta {...tag} key={tag.name || tag.property} />;
}

// export function renderLinkTag(tag) {
//   //   return null;
//   return <link {...tag} key={tag.rel} />;
// }
