import React from 'react';
import styled, { keyframes } from 'styled-components';

const green = '#24D024';
const yellow = '#fff952';
const pink = '#f53896';
const red = '#fb4545';
const blue = '#0A53DE';

const lightningRed = keyframes`
    0% {
        box-shadow: 5px 10px 35px 10px ${red};
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 5px 10px 35px 10px ${red};
    }
`;
const lightningGreen = keyframes`
    0% {
        box-shadow: 5px 0 35px 10px ${green};
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 5px 0 35px 10px ${green};
    }
`;
const lightningYellow = keyframes`
    0% {
        box-shadow: 5px 0 35px 10px ${yellow};
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 5px 0 35px 10px ${yellow};
    }
`;
const lightningBlue = keyframes`
    0% {
        box-shadow: 5px 0 35px 10px ${blue};
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 5px 0 35px 10px ${blue};
    }
`;
const lightningPink = keyframes`
    0% {
        box-shadow: 5px 0 35px 10px ${pink};
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 5px 0 35px 10px ${pink};
    }
`;

const Container = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  white-space: nowrap;
  z-index: 10;
  .line {
    padding: 0;
    list-style-type: none;
    text-align: center;
    overflow: none;
  }

  .red {
    background-color: ${red};
    animation: ${lightningRed} 1s infinite;
  }

  .green {
    background-color: ${green};
    animation: ${lightningGreen} 0.8s infinite;
  }

  .yellow {
    background-color: ${yellow};
    animation: ${lightningYellow} 0.9s infinite;
  }

  .blue {
    background-color: ${blue};
    animation: ${lightningBlue} 1.1s infinite;
  }

  .pink {
    background-color: ${pink};
    animation: ${lightningPink} 1.2s infinite;
  }

  @media (max-width: 1024px) {
    .line {
      padding: 0;
    }
    li {
      margin: 0 15px 20px 15px;
    }
  }
`;

const LightBulb = styled.li`
  position: relative;
  margin: 0 15px;
  list-style: none;
  padding: 0;
  display: inline-block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  top: 0px;
  background: #fff;
  &:before {
    content: '';
    position: absolute;
    background: #222;
    width: 10px;
    height: 9px;
    border-radius: 3px;
    top: -4px;
    left: 1px;
  }
  &:after {
    content: '';
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 19px;
    border-bottom: solid #222 2px;
    border-radius: 30%;
  }
`;

export default function LightBulbs() {
  return (
    <Container>
      <ul className='line'>
        <LightBulb className='red' />
        <LightBulb className='yellow' />
        <LightBulb className='blue' />
        <LightBulb className='pink' />
        <LightBulb className='red' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
        <LightBulb className='yellow' />
        <LightBulb className='red' />
        <LightBulb className='pink' />
        <LightBulb className='blue' />
        <LightBulb className='yellow' />
        <LightBulb className='red' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
        <LightBulb className='yellow' />
        <LightBulb className='red' />
        <LightBulb className='pink' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
        <LightBulb className='pink' />
        <LightBulb className='red' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
        <LightBulb className='yellow' />
        <LightBulb className='red' />
        <LightBulb className='pink' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
        <LightBulb className='pink' />
        <LightBulb className='red' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
        <LightBulb className='red' />
        <LightBulb className='yellow' />
        <LightBulb className='blue' />
        <LightBulb className='pink' />
        <LightBulb className='red' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
        <LightBulb className='yellow' />
        <LightBulb className='red' />
        <LightBulb className='pink' />
        <LightBulb className='blue' />
        <LightBulb className='yellow' />
        <LightBulb className='red' />
        <LightBulb className='green' />
        <LightBulb className='blue' />
      </ul>
    </Container>
  );
}
