function createParser(removeEntities = false) {
  //http://www.howtocreate.co.uk/sidehtmlentity.html
  const hexMap = {
    '&amp;': '&',
    '&#x5C;': '\\',
    '&#x2F;': '/',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x3D;': '='
  };
  //hope I get XSS after this :)
  const regexp = new RegExp(Object.keys(hexMap).join('|'), 'g');

  return function (str) {
    if (removeEntities) {
      return str.replace(regexp, '');
    }
    return str.replace(regexp, function (m) {
      return hexMap[m];
    });
  };
}

export const htmlDecode = createParser();

export const escapeHTMLEntities = (str) =>
  str.replace(/&(?:#x[a-f0-9]+|#[0-9]+|[a-z0-9]+);?/gi, '');

export function escapeHTML(unsafe) {
  return unsafe.replace(/<(?:.|\n)*?>/gm, '');
}

export function htmlDecodeObject(obj) {
  if (typeof obj !== 'object') {
    return obj;
  }
  for (var key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = htmlDecode(obj[key]);
    }
  }
  return obj;
}
