export function promisify(nodeFunction) {
  function promisified(...args) {
    return new Promise((resolve, reject) => {
      function callback(err, ...result) {
        if (err) return reject(err);
        if (result.length === 1) return resolve(result[0]);
        return resolve(result);
      }
      nodeFunction.call(null, ...args, callback);
    });
  }
  return promisified;
}

export function formUrlencoded(body) {
  const params = new URLSearchParams();
  for (var key in body) {
    params.append(key, body[key]);
  }
  return params;
}
